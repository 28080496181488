<template>
  <PulseTable :headers="headers" :items="items">
    <template #item="{ item }">
      <tr class="animate-pulse">
        <td v-for="col in item" :key="col">
          <LoaderBar class="w-2/3 h-5" />
        </td>
      </tr>
    </template>
  </PulseTable>
</template>

<script setup lang="ts">
const props = defineProps({
  contained: {
    default: false,
    type: Boolean,
  },
  headers: {
    required: true,
    type: Array as PropType<any[]>,
  },
  rowCount: {
    default: 5,
    type: Number,
  },
});

const items = computed(() => {
  const rows: string[][] = [];
  for (let i = 0; i < props.rowCount; i++) {
    const row: string[] = [];

    props.headers.forEach(header => {
      row.push(`${header.value}-${i}`);
    });
    rows.push(row);
  }

  return rows;
});
</script>
